import React from 'react';
import { useSelector } from 'react-redux';

import { FlowComponentType } from 'routes/types';

import Button from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import { HardOfferData } from 'handlers/applicationData';
import { formatNumber } from 'utils/formatNumber';
import { getApplicationData } from 'selectors/getApplicationData';
import { ReactComponent as TitleIcon } from 'images/offer-available-icon.svg';
import { getTypeOfLoan } from 'utils/getTypeOfLoan';

import styles from './OfferAvailable.module.scss';

const OfferAvailable = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const application = useSelector(getApplicationData)?.application;
  const { offerSummary } = (application?.hardOffer ?? {}) as HardOfferData;

  const loanOffer = offerSummary.loanAmount ?? 0;

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<TitleIcon />}
        title={
          <>
            You Qualify for a <span className={styles.titleHighlight}>${formatNumber(loanOffer)}</span>
            <br />
            Healthcare {getTypeOfLoan(application?.notEmployed)} Loan!
          </>
        }
        subtitle="Access below market interest rates exclusively for healthcare professionals."
      >
        <Button onClick={handleNext}>See My Savings</Button>
      </FormContainer>
    </>
  );
};

export default OfferAvailable;
