import { useSelector } from 'react-redux';

import { ReactComponent as BankAccount } from 'images/step-icons/bank-account-solid.svg';
import { ReactComponent as BriefCase } from 'images/step-icons/brief-case-solid.svg';
import { ReactComponent as License } from 'images/step-icons/license-solid.svg';
import { ReactComponent as Paystubs } from 'images/step-icons/paystubs-solid.svg';
import EmploymentDetailsStep from 'components/Verification/Steps/EmploymentDetails/EmploymentDetails';
import VerifyIdentityStep from 'components/Verification/Steps/VerifyIdentity';
import ArgyleIncomeVerification from 'components/Verification/Steps/IncomeVerification/Argyle';
import CheckingAccountStep from 'components/Verification/Steps/CheckingAccount/Plaid/Plaid';
import UploadIncomeVerification from 'components/Verification/Steps/IncomeVerification/Upload/UploadIncomeVerification';
import { getApplicationData } from 'selectors/getApplicationData';
import { Employer, completeVerificationStep, setCurrentVerificationStep, VerifyStatus } from 'handlers/applicationData';
import { FlowComponentType } from 'routes/types';
import { StepsNavigation } from 'components/Steps/StepsNavigation/StepsNavigation';
import { useSteps, StepData, StepMeta } from 'components/Steps/stepUtils';
import FirstTimeFlow from 'components/Steps/StepsFlow/FirstTimeFlow';
import ReturningFlow from 'components/Steps/StepsFlow/ReturningFlow';
import { RoutePath } from 'enums/Routes';
import { PayrollProviderName } from 'api/EmployersApi';

export enum VerificationStep {
  EmploymentDetails = 'employmentDetails',
  UploadPaystubs = 'paystubs',
  CheckingAccount = 'bankAccount',
  VerifyIdentity = 'kyc',
}

export enum IncomeVerificationType {
  Argyle = 'argyle',
  PaystubsUpload = 'paystubsUpload',
}

export type VerificationStepProgress = {
  employmentDetails: boolean;
  paystubs: boolean;
  bankAccount: boolean;
  kyc: boolean;
};

export const defaultVerificationProgress: Readonly<VerificationStepProgress> = Object.freeze({
  [VerificationStep.EmploymentDetails]: false,
  [VerificationStep.UploadPaystubs]: false,
  [VerificationStep.CheckingAccount]: false,
  [VerificationStep.VerifyIdentity]: false,
});

const isRelevantStep = (stepMeta: StepMeta | undefined, needsDocuments: boolean): boolean => {
  if (stepMeta && !needsDocuments) {
    return stepMeta.type !== IncomeVerificationType.PaystubsUpload;
  }
  return true;
};

export const getVerificationSteps: (
  hasArgyleSupport: boolean | undefined,
  hasCompletedBankAccount: boolean | undefined,
  needsDocuments: boolean | undefined,
) => StepData<VerificationStep>[] = (
  hasArgyleSupport: boolean | undefined,
  hasCompletedBankAccount: boolean | undefined,
  needsDocuments: boolean | undefined,
) => [
  {
    id: VerificationStep.EmploymentDetails,
    name: 'Employment Details',
    component: EmploymentDetailsStep,
    icon: BriefCase,
  },
  ...(hasArgyleSupport
    ? [
        {
          id: VerificationStep.UploadPaystubs,
          name: 'Verify Your Income',
          component: ArgyleIncomeVerification,
          icon: Paystubs,
          meta: {
            type: IncomeVerificationType.Argyle,
          },
        },
      ]
    : []),
  {
    id: VerificationStep.CheckingAccount,
    name: 'Connect Checking Account',
    component: CheckingAccountStep,
    icon: BankAccount,
  },
  {
    id: VerificationStep.VerifyIdentity,
    name: 'Verify Identity',
    component: VerifyIdentityStep,
    icon: License,
  },
  ...(hasCompletedBankAccount && needsDocuments
    ? [
        {
          id: VerificationStep.UploadPaystubs,
          name: 'Verify Your Income',
          component: UploadIncomeVerification,
          icon: Paystubs,
          meta: {
            type: IncomeVerificationType.PaystubsUpload,
          },
        },
      ]
    : []),
];

export const useVerificationSteps = () => {
  const { application } = useSelector(getApplicationData);
  const hasArgyleSupport = application?.employment.employer1.payrollProvider === PayrollProviderName.Argyle;

  const { verifiedName, verifiedAnnualIncome, verifiedHireDate } =
    application?.employment.employer1 ?? ({} as Employer);

  const verificationStepsStatus = application?.verificationStepProgress ?? defaultVerificationProgress;

  const needsDocuments =
    !hasArgyleSupport &&
    verifiedName === VerifyStatus.NotVerified &&
    verifiedHireDate === VerifyStatus.NotVerified &&
    verifiedAnnualIncome !== VerifyStatus.Verified;

  const verificationSteps = getVerificationSteps(hasArgyleSupport, verificationStepsStatus.bankAccount, needsDocuments);

  const steps = verificationSteps.filter((step) => isRelevantStep(step.meta, needsDocuments));

  return useSteps(
    useSelector(getApplicationData).currentVerificationStep ?? verificationSteps[0].id,
    setCurrentVerificationStep,
    completeVerificationStep,
    steps,
    verificationStepsStatus,
    RoutePath.VerifyingApplication,
  );
};

export const VerificationNavigation = (args: FlowComponentType) =>
  StepsNavigation<VerificationStep, VerificationStepProgress>(args, useVerificationSteps);
export const VerificationFirstTimeFlow = () =>
  FirstTimeFlow<VerificationStep, VerificationStepProgress>(useVerificationSteps);
export const VerificationReturningFlow = () =>
  ReturningFlow<VerificationStep, VerificationStepProgress>(useVerificationSteps, RoutePath.VerificationSteps);
