import { useSelector } from 'react-redux';
import { getMethodAuthData } from 'selectors/methodAuth';
import { getYourBirthDateData } from 'selectors/yourBirthDate';
import { FlowComponentType } from 'routes/types';
import { ErrorType as MethodErrorType } from 'api/MethodApi';
import { ApplyingResult as Result } from 'enums/FlowNextResults';
import { RoutePath } from 'enums/Routes';

import { useNavigate } from './useNavigate';

const useMethodAuth = () => {
  const navigate = useNavigate();
  const { entityId, failed: methodAuthFailed, errorType: methodErrorType } = useSelector(getMethodAuthData);
  const { birth_date: birthDate } = useSelector(getYourBirthDateData);

  const processMethodErrorAndRedirect = (handleNext: FlowComponentType['handleNext']): boolean => {
    if (methodAuthFailed) {
      switch (methodErrorType) {
        case MethodErrorType.UnauthorizedSessionToken:
          handleNext(Result.SessionExpired);
          return true;
        case MethodErrorType.CannotRetrieveData:
          if (!birthDate) {
            handleNext(Result.CannotRetrieveMethodData);
            return true;
          }
          break;
        case MethodErrorType.InvalidZipCode:
          navigate(RoutePath.YourAddress);
          return true;
        default:
          if (!entityId) {
            handleNext(Result.Error);
            return true;
          }
          break;
      }
    }

    return false;
  };

  return { processMethodErrorAndRedirect };
};

export default useMethodAuth;
