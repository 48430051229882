import { CurrentFlow } from 'enums/CurrentFlow';
import { RoutePath } from 'enums/Routes';
import { getApplicationData as getApplicationDataThunk } from 'thunks';
import { FLOW_TO_STEP_MAP, setApplicationFlow } from 'handlers/applicationStep';
import YourName, { YourNameState } from 'components/LoanForm/YourName/YourName';
import CheckupAnalyzing from 'components/FinancialCheckup/Analyzing/Analyzing';
import CardApplying from 'components/CardFlow/Applying';
import StudentLoanForgivenessAnalyzing from 'components/StudentLoanForgiveness/Analyzing';
import CustomizeCard from 'components/CardFlow/Customize/Customize';
import Loader from 'components/Card/Loader';
import PopBackRedirect from 'components/PopBackRedirect';
import ErrorConsolidating from 'components/DebtConsolidation/ErrorConsolidating';
import { ApplyingResult, CardNextResult, LoaderResult, YourNameResult } from 'enums/FlowNextResults';
import ExitSurvey from 'components/ExitSurvey';
import NotEligible from 'components/StudentLoanForgiveness/NotEligible';
import { getStudentLoanForgivenessRoutes, getStudentLoanStart } from 'routes/StudentLoanForgivenessRoutes';
import { StudentLoanEligibilityResult } from 'enums/StudentLoanForgivenessFlowResults';
import { getInjectedStoreState } from 'store/injectStore';

import { getDebtConsolidationRoutes } from './DebtConsolidationRoutes';
import { getMissedPaymentRoutes } from './MissedPaymentRoutes';
import { getApplyStageRoutes } from './GetApplyStageRoutes';
import { CustomRouterType, FlowRouterType } from './types';
import { getCardRoutes } from './CardRoutes';
import { getFinancialCheckupRoutes, backToYourFinances } from './FinancialCheckupRoutes';

const YOUR_NAME_ROUTER: CustomRouterType = {
  navigationInfo: { showBackLink: false, title: 'Create Account', step: 1, stepCount: 5 },
  component: YourName,
  handleNext: ({ navigate }) => () => {
    navigate(RoutePath.YourPhoneNumber);
  },
};

const CUSTOMIZE_CARD_ROUTER: CustomRouterType = {
  navigationInfo: { showBackLink: true, title: 'Sign Up', step: 1, stepCount: 5 },
  component: CustomizeCard,
  handleNext: ({ navigate }) => (result) => {
    switch (result) {
      case YourNameResult.Exit:
        navigate(RoutePath.ExitSurvey);
        break;
      default:
        navigate(RoutePath.YourPhoneNumber);
        break;
    }
  },
};

const FlowRouter: FlowRouterType = {
  [CurrentFlow.FinancialCheckup]: {
    ...getApplyStageRoutes(YOUR_NAME_ROUTER, CheckupAnalyzing, ({ navigate }) => (result) => {
      switch (result) {
        case ApplyingResult.CannotRetrieveMethodData:
          navigate(RoutePath.YourBirthDate);
          break;
        case ApplyingResult.SessionExpired:
          navigate(RoutePath.YourName, { state: { isSessionExpired: true } as YourNameState });
          break;
        case ApplyingResult.Error:
          navigate(RoutePath.Error);
          break;
        default:
          navigate(RoutePath.YourFinances);
      }
    }),
    ...getFinancialCheckupRoutes(),
    ...getDebtConsolidationRoutes(backToYourFinances),
    ...getStudentLoanForgivenessRoutes(backToYourFinances),
    ...getCardRoutes(true, backToYourFinances),

    [RoutePath.XSellLoader]: {
      // This route exists for when the flow gets replaced with
      // Financial Checkup Flow and it was previously in another flow
      // and customer goes clicks on the back button.
      component: PopBackRedirect,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.CardApplicationSuccessful);
      },
    },
  },
  [CurrentFlow.DebtConsolidation]: {
    ...getApplyStageRoutes(YOUR_NAME_ROUTER, CheckupAnalyzing, ({ navigate }) => (result) => {
      // For some reason, the state argument gives an older snapshot that does not provide the application.
      const state = getInjectedStoreState();
      switch (result) {
        case ApplyingResult.CannotRetrieveMethodData:
          navigate(RoutePath.YourBirthDate);
          break;
        case ApplyingResult.Error:
          navigate(RoutePath.Error);
          break;
        case ApplyingResult.SessionExpired:
          navigate(RoutePath.YourName, { state: { isSessionExpired: true } as YourNameState });
          break;
        default:
          navigate(FLOW_TO_STEP_MAP[CurrentFlow.DebtConsolidation](state.applicationData.application).path);
      }
    }),
    ...getDebtConsolidationRoutes(backToYourFinances),
    ...getStudentLoanForgivenessRoutes(backToYourFinances),
    ...getCardRoutes(true, backToYourFinances),
    ...getFinancialCheckupRoutes(),
  },
  [CurrentFlow.Card]: {
    ...getApplyStageRoutes(CUSTOMIZE_CARD_ROUTER, CardApplying, ({ navigate }) => () => {
      navigate(RoutePath.ReferralProgram);
    }),
    [RoutePath.ExitSurvey]: {
      navigationInfo: { showBackLink: false, title: 'Exit Survey' },
      component: ExitSurvey,
    },
    ...getCardRoutes(false, ({ state, navigate }) => (result) => {
      if (result === CardNextResult.FromCardApplied && state.studentLoanData.eligible) {
        navigate(getStudentLoanStart(state.studentLoanData));
      } else if (state.paycheckSwitchData.eligible && !state.studentLoanData.eligible) {
        navigate(RoutePath.ConnectPaycheck);
      } else {
        navigate(RoutePath.YourFinances);
      }
    }),
    [RoutePath.XSellLoader]: {
      component: Loader,
      handleNext: ({ navigate }) => (result) => {
        switch (result) {
          case LoaderResult.Success:
            // when the offer succeeds and the application gets loaded the resume
            // process will get triggered in src/routes/index.tsx, so we shouldn't
            // navigate anywhere here
            break;
          case LoaderResult.NoOffer:
          case LoaderResult.CantGenerateOffer:
            navigate(RoutePath.ErrorConsolidating);
            break;
          default:
            navigate(RoutePath.Error);
            break;
        }
      },
    },
    [RoutePath.ErrorConsolidating]: {
      navigationInfo: { showBackLink: false },
      component: ErrorConsolidating,
      handleNext: ({ dispatch, dispatchWithUnwrap, navigate, state }) => async () => {
        await dispatchWithUnwrap(getApplicationDataThunk(state.loanOffer.response.data.application_id!));
        dispatch(setApplicationFlow({ currentFlow: CurrentFlow.FinancialCheckup }));
        navigate(RoutePath.YourFinances);
      },
    },
    [RoutePath.YourFinances]: {
      // This route will be replaced when application is fetched
      component: Loader,
      handleNext: ({ dispatchWithUnwrap, state }) => () => {
        dispatchWithUnwrap(getApplicationDataThunk(state.loanOffer.response.data.application_id!));
      },
    },
    ...getDebtConsolidationRoutes(backToYourFinances),
    ...getStudentLoanForgivenessRoutes(({ state, navigate }) => () => {
      if (state.paycheckSwitchData.eligible) {
        navigate(RoutePath.ConnectPaycheck);
      } else {
        navigate(RoutePath.ReferralProgram);
      }
    }),
  },
  [CurrentFlow.StudentLoanForgiveness]: {
    ...getApplyStageRoutes(
      YOUR_NAME_ROUTER,
      StudentLoanForgivenessAnalyzing,
      ({ navigate, state }) => async (result) => {
        switch (result) {
          case StudentLoanEligibilityResult.Eligible:
          case StudentLoanEligibilityResult.NotEligible:
            navigate(getStudentLoanStart(state.studentLoanData));
            break;
          case StudentLoanEligibilityResult.CannotRetrieveMethodData:
            navigate(RoutePath.YourBirthDate);
            break;
          case StudentLoanEligibilityResult.SessionExpired:
            navigate(RoutePath.YourName, { state: { isSessionExpired: true } as YourNameState });
            break;
          case ApplyingResult.Error:
            navigate(RoutePath.Error);
            break;
          default:
            // TODO: Add Indetermined status vs Ineligible
            // navigate(RoutePath.StudentLoanNotEligible);
            navigate(RoutePath.StudentLoanBookAdvisor);
            break;
        }
      },
    ),
    [RoutePath.StudentLoanNotEligible]: {
      navigationInfo: { showBackLink: false },
      component: NotEligible,
      handleNext: ({ navigate }) => () => {
        navigate(RoutePath.YourFinances);
      },
    },
    ...getStudentLoanForgivenessRoutes(backToYourFinances),
    ...getDebtConsolidationRoutes(backToYourFinances),
    ...getCardRoutes(true, backToYourFinances),
    ...getFinancialCheckupRoutes(),
  },
  [CurrentFlow.MissedPayment]: { ...getMissedPaymentRoutes() },
};

export default FlowRouter;
