import React from 'react';

import { NavigateOptions } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import {
  HardOfferData,
  StudentLoanAssistanceAppointment,
  StudentLoanAssistancePaymentStatus,
} from 'handlers/applicationData';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import { ReactComponent as AdditionalFundsIcon } from 'images/action-items/additional-funds.svg';
import { ReactComponent as SignLoanAgreementIcon } from 'images/action-items/sign-agreement.svg';
import { ReactComponent as SetupRepaymentIcon } from 'images/action-items/setup-repayment.svg';
import { ReactComponent as VerifyEmploymentIcon } from 'images/action-items/suitcase.svg';
import { ReactComponent as StudentLoanIcon } from 'images/action-items/student-loan.svg';
import { ReactComponent as PlanneryCardIcon } from 'images/action-items/credit-card.svg';
import { ReactComponent as ReferralProgramIcon } from 'images/action-items/spread-word.svg';
import { ReactComponent as DebtConsolidationIcon } from 'images/action-items/down-trend.svg';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getStudentLoanStart } from 'routes/StudentLoanForgivenessRoutes';
import { useApplySteps } from 'components/StudentLoanForgiveness/Apply/applySteps';
import { PartnerData, usePartnerData } from 'enums/PartnerName';
import { formatNumber } from 'utils/formatNumber';
import { getTypeOfLoan } from 'utils/getTypeOfLoan';

export interface ActionItem {
  id: string;
  name: string;
  description: JSX.Element;
  icon: React.FunctionComponent<{ className?: string }>;
  route: RoutePath;
  visible: boolean;
  routeOptions?: NavigateOptions;
}

interface ItemsData {
  moneySaved?: number;
  monthsSaved?: number;
  loanAmount?: number;
  maxLoanAmount?: number;
  additionalFundsEligibility: boolean;
  additionalFundsInterestDifference: number;
  payOff?: number;
  notEmployed?: boolean;
  studentLoanEligible: boolean;
  studentLoansBalance?: number;
  studentLoanAssistanceInProgress: boolean;
  studentLoanAssistanceCompleted: boolean;
  studentLoanAssistanceAppointment?: StudentLoanAssistanceAppointment;
  waitlistPosition?: number;
  waitlistLength?: number;
  cardCashbackPercentage: number;
  cardApplied: boolean;
  applicationStatus?: ApplicationStatusName;
  paymentActiveOrCompleted?: boolean;
  partnerData: PartnerData;
}

export const useActionItems = () => {
  const { totalBalance, eligible } = useSelector(getStudentLoanData);
  const {
    applicationData: { paymentStatus },
  } = useSelector(getStudentLoanApplication);
  const { waitListPosition, waitListLength, applied: cardApplied } = useSelector(getCardData);
  const { application } = useSelector(getApplicationData);
  const { stepsCompleted, stepsStarted } = useApplySteps();

  const { status, studentLoanAssistanceAppointment } = application ?? {};
  const hardOffer = application?.hardOffer as HardOfferData | undefined;

  const paymentActiveOrCompleted =
    paymentStatus === StudentLoanAssistancePaymentStatus.Active ||
    paymentStatus === StudentLoanAssistancePaymentStatus.Completed;

  const partnerData = usePartnerData();

  const additionalFundsInterestDifference =
    !!hardOffer?.planneryLoan.apr && !!hardOffer?.additionalFundsTotal.apr
      ? (hardOffer?.additionalFundsTotal.apr / hardOffer?.planneryLoan.apr - 1) * 100
      : 0;

  return actionItems({
    ...hardOffer?.offerSummary,
    notEmployed: application?.notEmployed,
    studentLoanEligible: eligible === true,
    studentLoansBalance: totalBalance,
    studentLoanAssistanceInProgress: stepsStarted,
    studentLoanAssistanceCompleted: stepsCompleted,
    studentLoanAssistanceAppointment,
    waitlistPosition: waitListPosition,
    waitlistLength: waitListLength,
    cardCashbackPercentage: 5,
    cardApplied: cardApplied ?? false,
    applicationStatus: status,
    paymentActiveOrCompleted,
    partnerData,
    additionalFundsEligibility: application?.additionalFundsEligibility ?? false,
    additionalFundsInterestDifference,
    payOff: hardOffer?.payOffTotal.amount,
    maxLoanAmount: application?.maxLoanAmount,
  });
};

const timeSavedLabel = (prefix: string, monthsSaved?: number) => {
  if (!monthsSaved || monthsSaved <= 0) {
    return null;
  }

  const timeText =
    monthsSaved >= 36
      ? `${Math.floor(monthsSaved / 12)} years earlier`
      : `${monthsSaved} month${monthsSaved > 1 && 's'} earlier`;

  return (
    <>
      {' '}
      {prefix}
      <strong> {timeText}</strong>
    </>
  );
};

export const actionItems = ({
  moneySaved,
  monthsSaved,
  loanAmount,
  additionalFundsEligibility,
  additionalFundsInterestDifference,
  payOff,
  maxLoanAmount,
  notEmployed,
  studentLoanEligible,
  studentLoansBalance,
  studentLoanAssistanceInProgress,
  studentLoanAssistanceCompleted,
  studentLoanAssistanceAppointment,
  waitlistPosition,
  cardCashbackPercentage,
  cardApplied,
  applicationStatus,
  paymentActiveOrCompleted,
}: ItemsData) => {
  const studentLoanDataState = useSelector(getStudentLoanData);

  const items = [
    {
      id: 'signLoanAgreement',
      name: 'Sign Loan Agreement',
      description: (
        <span>
          You're almost at the finish line! On your {formatMonetaryAmount(loanAmount)} in personal debt, Plannery can
          save you <strong>{formatMonetaryAmount(moneySaved)}</strong>
          {timeSavedLabel('and get out of debt', monthsSaved)}.
        </span>
      ),
      icon: SignLoanAgreementIcon,
      route: RoutePath.DocuSignSignature,
      visible: applicationStatus === ApplicationStatusName.SigningAgreement,
    },
    {
      id: 'setUpRepayment',
      name: 'Set Up Repayment',
      icon: SetupRepaymentIcon,
      description: (
        <span>
          You're close to saving! On your {formatMonetaryAmount(loanAmount)} in personal debt, Plannery can save you{' '}
          <strong>{formatMonetaryAmount(moneySaved)}</strong>
          {timeSavedLabel('and get out of debt', monthsSaved)}.
        </span>
      ),
      route: RoutePath.RepaymentMethod,
      visible: applicationStatus === ApplicationStatusName.SetUpDeduction,
    },
    {
      id: 'verifyEmployment',
      name: 'Verify Employment',
      icon: VerifyEmploymentIcon,
      description: (
        <span>
          We need to verify some information so you can save! On your {formatMonetaryAmount(loanAmount)} in personal
          debt, Plannery can save you <strong>{formatMonetaryAmount(moneySaved)}</strong>
          {timeSavedLabel('and get out of debt', monthsSaved)}.
        </span>
      ),
      route: RoutePath.SetupAccount,
      visible: applicationStatus === ApplicationStatusName.Verification,
    },
    {
      id: 'debtConsolidation',
      name: 'Reduce High Interest Debt',
      description: (
        <span>
          Plannery can save you <strong>{formatMonetaryAmount(moneySaved)}</strong> and help you pay off{' '}
          {formatMonetaryAmount(payOff)} in personal debt
          {timeSavedLabel('', monthsSaved)}!
        </span>
      ),
      icon: DebtConsolidationIcon,
      route: RoutePath.YourSavings,
      visible: applicationStatus === ApplicationStatusName.OfferAvailable && payOff !== undefined && payOff > 0,
    },
    {
      id: 'studentLoanForgiveness',
      name: 'Get Student Loans Forgiven',
      description: (
        <span>
          Your <strong>{formatMonetaryAmount(studentLoansBalance)}</strong> in federal student loans may qualify for
          assistance.
        </span>
      ),
      icon: StudentLoanIcon,
      route: getStudentLoanStart(studentLoanDataState),
      visible: studentLoanEligible && !studentLoanAssistanceAppointment && !studentLoanAssistanceInProgress,
    },
    {
      id: 'studentLoanForgivenessInProgress',
      name: 'Continue Your Student Loan Forgiveness Application',
      description: (
        <span>
          Your <strong>{formatMonetaryAmount(studentLoansBalance)}</strong> in federal student loans may qualify for
          assistance.
        </span>
      ),
      icon: StudentLoanIcon,
      route: paymentActiveOrCompleted ? RoutePath.StudentLoanApplySteps : RoutePath.StudentLoanApplySubmit,
      visible:
        studentLoanEligible &&
        studentLoanAssistanceInProgress &&
        (!studentLoanAssistanceCompleted || !paymentActiveOrCompleted),
    },
    {
      id: 'planneryCard',
      name: 'Join the Plannery Card Waitlist',
      description: (
        <span>
          Exceptional care deserves exceptional perks: Earn up to <strong>{cardCashbackPercentage}% back</strong> on
          your purchases and pay <strong>90% less interest</strong> than the average credit card.
        </span>
      ),
      icon: PlanneryCardIcon,
      route: RoutePath.BuildCreditAvoidDebtSaveMoney,
      visible: !cardApplied,
    },
    {
      id: 'additionalFunds',
      name: `Save with a Healthcare ${getTypeOfLoan(notEmployed)} Loan`,
      description: (
        <span>
          Borrow up to <strong>{formatMonetaryAmount(maxLoanAmount)}</strong> with Plannery
          {additionalFundsInterestDifference > 0 ? (
            <>
              {' at interest rates '}
              {additionalFundsInterestDifference >= 20 ? (
                <strong>{formatNumber(additionalFundsInterestDifference)}%</strong>
              ) : null}
              {' below the market average.'}
            </>
          ) : null}
        </span>
      ),
      icon: AdditionalFundsIcon,
      route: RoutePath.YourSavings,
      visible: applicationStatus === ApplicationStatusName.OfferAvailable && additionalFundsEligibility,
    },
    {
      id: 'referralProgram',
      name: 'Spread The Word',
      description: (
        <span style={{ marginBottom: '12px', display: 'block' }}>
          You're<strong>{waitlistPosition ? ` #${waitlistPosition}` : ''}</strong> on the waitlist for Plannery Card.
          Spread the word and <strong>help us launch!</strong>
        </span>
      ),
      icon: ReferralProgramIcon,
      route: RoutePath.ReferralProgram,
      routeOptions: { state: { fromYourFinances: true } },
      visible: cardApplied,
    },
  ];

  return {
    actionItems: items,
    nextRoute: getFirstVisibleAction(items)?.route,
  };
};

const getFirstVisibleAction = (items: ActionItem[]) => items.find((item) => item.visible);
